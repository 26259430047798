<template>
  <header id="header">
    <img
        @mouseover="() => {showMenuServices = true}"
        v-show="showMenuServices"  src="@/assets/img/background_header_menu.png" alt="logo" class="header_background"
        @mouseleave="() => {showMenuServices = false}"
    >

    <div class="container__header">
      <router-link to="/" class="logo">
        <!--
                        <img src="@/assets/img/LogoMobileSvg.svg" alt="logo" class="logo__img">
        -->
        <img  src="https://dbwImages.b-cdn.net/LogoMobileSvg.svg" alt="logo" class="logo__img">

      </router-link>



      <nav v-if="this.menuForServices" class="nav__container">
        <router-link :class="{'nav__container__active': $route.name === 'reel'}" :to="{name: 'reel'}">
          {{ this.menuForServices[2].name }}
        </router-link>
        <router-link :class="{'nav__container__active': $route.name === 'about'}" :to="{name: 'about'}">
          {{ this.menuForServices[3].name }}
        </router-link>
        <router-link :class="{'nav__container__active': $route.name === 'portfolio'}" :to="{name: 'portfolio'}">
          {{ this.menuForServices[0].name }}
        </router-link>
        <router-link :class="{'nav__container__active': $route.name === 'blog'}" :to="{name: 'blog'}">
          {{ this.menuForServices[1].name }}
        </router-link>
        <div
            @mouseover="() => {showMenuServices = true}"
            v-if="this.menuForServices"
            @mouseleave="() => {showMenuServices = false}"
        >
          <div
              class="drop__services"
          >
            <p
                class="nav__container__services"
                :class="{'nav__container__active__services': $route.name === 'services'}"
            >
              {{ this.menuForServices[4].name }}
            </p>
            <img :class="[this.showMenuServices ? 'services__drop__active' : 'services__drop']"
                 src="../assets/img/drop_down_services.png" alt="drop__services">
          </div>
          <transition name="fade1">
            <open-services-menu
                @closeServiceMenu="() => {showMenuServices = false}"
                v-show="showMenuServices"
                :is-open-services-menu="showMenuServices"
                :footer-position="false"
            />
          </transition>
        </div>


      </nav>

      <a @mouseover="contactMouseOver = true" @mouseleave="contactMouseOver = false"
         href="#" v-scroll-to="{ el: '#contact', duration: 1000 }" class="border_btn_redisign">

        <img v-if="!contactMouseOver" src="@/assets/img/paylog_redisigne.png" alt="payload" class="btn__img">
        <img v-if="contactMouseOver" src="@/assets/img/Polygon5.svg" alt="payload" class="btn__img">

        <p class="btn__text" :class="[contactMouseOver ? 'btn__text_red' : 'btn__text']">CONTACT</p>

      </a>


      <div class="btn__burger" @click="openMenu">
        <div class="button__burger" :class="{ open: showMenu }">
          <span></span>
          <span></span>
        </div>
      </div>

    </div>

    <transition name="fade">
      <open-mobile-menu
          v-show="showMenu"
          :is-open="showMenu"
          @close="openMenu"
      />
    </transition>


  </header>
</template>

<script>
import {bus} from '../main'
import {mapGetters} from 'vuex'

const OpenMobileMenu = () => import('./OpenMobileMenu');
const OpenServicesMenu = () => import('./OpenServicesMenu');

export default {
  name: 'Header',
  components: {OpenServicesMenu, OpenMobileMenu},
  data() {
    return {
      activeLink: false,
      showMenu: false,
      showMenuServices: false,
      contactMouseOver: false,
    }
  },
  computed: {
    ...mapGetters([
      /* 'menuLinkText',*/
      'menuForServices'
    ])
  },
  created() {
    this.$store.dispatch('getMenuServices');
  },
  mounted() {
    const header = this.$scrollmagic.scene({
      triggerElement: 'body',
      triggerHook: 0,
      offset: 120,
      /*
                  duration: document.height
      */
    })
        .setClassToggle('#header', 'is-hidden')
    this.$scrollmagic.addScene(header)

  },
  methods: {
    showReel: function (el) {
      bus.$emit('stateReel', el);
    },
    openMenu() {
      console.log(document.body.style.overflow);
      this.showMenu = !this.showMenu;
      document.body.style.overflow = !this.showMenu ? '' : 'hidden';

    },
  },
  watch: {
    '$route'(to) {
      if (to === this.$route.name) {
        this.activeLink = true

      }
    }
  },
  beforeDestroy() {
    if (this.showMenu) {
      this.openMenu();
    }
    if (this.showMenuServices) {
      this.showMenuServices = false;
    }
  },
}
</script>

<style scoped lang="scss">
.header_background{
  display: block;
  position: fixed;
  top: 0;
  right: 4vw;
  z-index: 555;
  width: 29vw;
}

.services__drop {
  margin-left: 0.417vw;
  transform: rotate(180deg);
  margin-bottom: 0.2vw;
}

.drop__services {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;
  cursor: pointer;
}

.drop__services a {
  user-select: none;
}

.services__drop__active {
  transform: rotate(360deg);
  margin-left: 0.417vw;
  margin-bottom: 0.3vw;


}

.logo {
  margin-left: 8.3vw;
  margin-top: 0.677vw;
  width: 4.740vw;
  height: 6.042vw;
}

.logo__img {
  width: 100%;
  height: 100%;
}

.border_btn_redisign {
  position: relative;
  top: -0.260vw;
}

.border_btn_redisign img {
  width: 6.354vw;
  height: 4.740vw;
}

.btn__text {
  font-family: 'Benzin-Regular';
  font-size: 0.938vw;
  position: absolute;
  top: 2.083vw;
  color: #FFFFFF;
  left: 50%;
  transform: translate(-50%);
}

.btn__text_red {
  font-family: 'Benzin-Regular';
  font-size: 0.938vw;
  position: absolute;
  top: 2.083vw;
  color: red;
  left: 50%;
  transform: translate(-50%);
}

.nav__container {
  display: flex;
  justify-content: space-between;
  max-width: 46.03vw;
  width: 100%;
  margin-top: 1.927vw;

  margin-left: 15.94vw;
  margin-right: 13.44vw;

  height: 20%;
  z-index: 9999999;
  position: relative;
}

.nav__container__active {
  font-size: 0.938vw;
  text-transform: uppercase;
  transition: 0.5s;
  color: red;
}
.nav__container__services{
  font-size: 0.938vw;
  text-transform: uppercase;
  transition: 0.5s;
  color: #FFFFFF
}
.nav__container__services:hover{
  font-size: 0.938vw!important;
  text-transform: uppercase!important;
  transition: 0.5s!important;
  color: #FFFFFF!important;
  opacity: 0.5!important;
}
.nav__container__active__services {
  font-size: 0.938vw!important;
  text-transform: uppercase!important;
  transition: 0.5s!important;
  color: #FFFFFF!important;
  opacity: 0.5!important;
}
.nav__container__active__services:hover {
  font-size: 0.938vw!important;
  text-transform: uppercase!important;
  transition: 0.5s!important;
  color: #FFFFFF!important;
  opacity: 0.5!important;
}


@media screen and(max-width: 991px) {
  .header_background{
    display: none;
  }
  .logo {
    margin-left: 6.4%;
    width: 14.133vw;
    height: 17.867vw;
  }
  .border_btn_redisign {
    display: none;
  }
  .nav__container {
    display: none;
  }
  .btn__burger {
    width: 8.533vw;
    height: 3.200vw;
    margin-right: 8.267vw;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .button__burger {
    z-index: 999999;
    top: 2.667vw;
    width: 8.533vw;
    height: 3.200vw;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    transition: 0.5s;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .button__burger span {
    display: block;
    position: absolute;
    height: 0.800vw;
    width: 8.533vw;
    background: rgba(229, 220, 220, 1);
    border-radius: 0;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .35s ease-in-out;
  }

  .button__burger span:nth-child(1) {
    top: 0.800vw;
    transform-origin: left center;
  }

  .button__burger span:nth-child(2) {
    margin-left: 1.867vw;
    width: 6.667vw;
    top: 3.733vw;
    transform-origin: left center;
  }

  .button__burger.open span:nth-child(2) {
    width: 8.533vw;
    transform: rotate(-45deg);
    top: 6.400vw;
    left: -2.133vw;
  }

  .button__burger.open span:nth-child(1) {
    transform: rotate(45deg);
    top: 0.533vw;
    left: -0.267vw;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
  {
    opacity: 0;
  }

  .fade-leave-active {
    transition-delay: .6s;
  }

  .fadeButton-enter-active, .fadeButton-leave-active {
    transition: opacity .3s;
  }
  .fadeButton-enter, .fadeButton-leave-to /* .fade-leave-active до версии 2.1.8 */
  {
    opacity: 0;
  }

}

</style>
